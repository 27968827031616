<template>
    <div class="page-table scrollable mainDiv">
        <div class="card-base baslikBg" v-if="this.selectIcon===0">Reddedilen Başvurular</div>
        <div class="card-base baslikBg" v-if="this.selectIcon===1">Onaylanan Başvurular</div>
        <div class="card-base baslikBg" v-if="this.selectIcon===2">Onay Bekleyen Başvurular</div>
        <div class="page-header card-base card-shadow--medium animated fadeInUp">
            <el-col :span="24">
                <div>
                    <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9"
                                    text-color="gray">
                        <el-radio-button label="1">
                            <i class="mdi mdi-email-open mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                            <transition
                                    name="slide-fade"><label v-if="this.selectIcon===1">Onaylanan Başvurular</label>
                            </transition>
                        </el-radio-button>
                        <el-radio-button label="2">
                            <i class="mdi mdi-email mdi-18px" style="color: #f7ba2a; margin-right: 5px"></i>
                            <transition
                                    name="slide-fade"><label v-if="this.selectIcon===2">Onay Bekleyen Başvurular</label>
                            </transition>
                        </el-radio-button>
                        <el-radio-button label="0">
                            <i class="mdi mdi-email mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                            <transition
                                    name="slide-fade"><label v-if="this.selectIcon===0">Reddedilen Başvurular</label>
                            </transition>
                        </el-radio-button>
                    </el-radio-group>
                </div>
            </el-col>
            <el-table
                    stripe
                    border
                    v-loading="loading"
                    :data="basvuruListe"
                    ref="filterTable"
                    style="width: 100%">
                <el-table-column
                        sortable
                        prop="firma"
                        label="Firma Adı"
                        width="auto">
                </el-table-column>
                <el-table-column
                        prop="vergiNo"
                        label="Vergi No"
                        width="200">
                </el-table-column>
                <el-table-column
                        prop="adres"
                        label="Adres"
                        width="auto">
                </el-table-column>
                <el-table-column
                        prop="telefon"
                        label="Telefon"
                        width="200">
                </el-table-column>
                <el-table-column
                        label="İl/İlçe"
                        width="300">
                    <template slot-scope="props">
                        {{props.row.il}} / {{props.row.ilce}}
                    </template>
                </el-table-column>

                <el-table-column
                        :label='$t("src.views.apps.genel.islem")'
                        align="right"
                        width="180">
                    <template slot-scope="scope">
                        <el-tooltip :content='$t("src.views.apps.genel.detay")'
                                    :open-delay="500"
                                    placement="bottom">
                            <el-button
                                    type="text"
                                    @click="basvuruDetay(scope.row)"
                                    icon="mdi mdi-email-search mdi-24px">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip content='Reddet' :open-delay="500"
                                    placement="bottom">
                            <el-button
                                    v-if="selectIcon ===2 || selectIcon === 1"
                                    @click="basvuruDurumChange(scope.row,scope.$index,basvuruListe,'0')"
                                    class="buttonDel"
                                    type="text"
                                    icon="mdi mdi-delete mdi-24px">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip content='Onayla' :open-delay="500"
                                    placement="bottom">
                            <el-button
                                    v-if="selectIcon ===2 || selectIcon === 0"
                                    @click="basvuruDurumChange(scope.row,scope.$index,basvuruListe,'1')"
                                    class="buttonEdit"
                                    style="color: #00c000"
                                    type="text"
                                    icon="mdi mdi-play mdi-24px">
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    layout="sizes,prev, pager, next, jumper, total"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="pageChange"
                    :page-sizes="[10, 20, 50, 100]"
                    :current-page.sync="page"
                    :next-click.sync="page"
                    :prev-click.sync="page"
                    @next-click="pageChange"
                    @prev-click="pageChange">
            </el-pagination>
        </div>
        <el-dialog
                :title='$t("src.views.apps.genel.uyari")'
                :visible.sync="arsivDialogVisible"
                width="30%"
                center>
            <span>"{{selection.adSoyad}}" {{$t('src.views.apps.iletisim.liste.alertP')}}</span>
            <span slot="footer" class="dialog-footer">
                    <el-button icon="el-icon-check"
                               type="success"
                               size="mini"
                               @click="basvuruDurumChange(selection,selectionIndex,basvuruListe), arsivDialogVisible = false"></el-button>
                    <el-button size="mini" type="danger" icon="el-icon-close"
                               @click="arsivDialogVisible = false"></el-button>
            </span>
        </el-dialog>
        <el-drawer
                :title="basvuruDetayM.firma+' Başvuru Detayı'"
                :visible.sync="drawer"
                size="40%"
                :with-header="true">
            <div class="flex center demo-box">
                <div class="right-box box grow">
                    <dl class="horizontal" style="padding: 10px">
                        <dt>Firma Adı</dt>
                        <dd> : {{basvuruDetayM.firma}}</dd>
                        <el-divider></el-divider>
                        <dt>Telefon</dt>
                        <dd> : {{basvuruDetayM.telefon}}</dd>
                        <el-divider></el-divider>
                        <dt>E-Posta</dt>
                        <dd> : {{basvuruDetayM.mail}}</dd>
                        <el-divider></el-divider>
                        <dt>Vergi No</dt>
                        <dd> : {{basvuruDetayM.vergiNo}}</dd>
                        <el-divider></el-divider>
                        <dt>İl/İlçe</dt>
                        <dd> : {{basvuruDetayM.il}} / {{basvuruDetayM.ilce}}</dd>
                        <el-divider></el-divider>
                        <dt>Çalıştığı Firmalar</dt>
                        <dd> : {{basvuruDetayM.calistigiFirmalar}}</dd>
                        <el-divider></el-divider>
                        <dt>Firma Geçmişi</dt>
                        <dd> : {{basvuruDetayM.firmaGecmisi}}</dd>
                        <el-divider></el-divider>
                    </dl>
                </div>
            </div>
        </el-drawer>

    </div>
</template>

<script>
    import iletisimService from '../../../WSProvider/IletisimService';
    import notification from '../../../notification'

    let base64 = require('base64_js');
    let userDataBGSurec = "";
    export default {
        name: "BasvuruListesi",
        data() {
            return {
                page: 0,
                sizes: 10,
                postPage: '',
                total: null,
                drawer: false,
                notDialog: false,
                basvuruDetayM: [],
                form:{
                    not:''
                },
                loading: false,
                arsivDialogVisible: false,
                selection: '',
                selectionIndex: '',
                basvuruListe: [],
                selectIcon: 2,
                radio: '2',
            }
        },
        watch: {
            page(val) {
                this.postPage = (val - 1) * this.sizes;
                this.getBasvuruList(this.selectIcon, this.postPage, this.sizes);
            },
        },
        mounted() {
            if (localStorage.getItem("userDataBGSurec")) {
                userDataBGSurec = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataBGSurec"))));
            }
        },
        methods: {
            handleSizeChange(val) {
                this.sizes = val;
                this.getBasvuruList(this.selectIcon, this.postPage, this.sizes);
            },
            handleChange(event) {
                this.loading = true;
                this.selectIcon = parseInt(event);
                this.postPage = 0;
                this.page = 1;
                this.getBasvuruList(this.selectIcon, this.postPage, this.sizes);
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },
            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.getBasvuruList(this.selectIcon, this.postPage, this.sizes);
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },
            getBasvuruList(durum, baslangici, limit) {
                let self = this;
                iletisimService.bayiBasvuruList(durum, baslangici, limit).then((response) => {
                    if (response.status === 200) {
                        this.basvuruListe = response.data;
                        setTimeout(function () {
                            self.loading = false;
                        }, 1000);
                    } else {
                        notification.Status(response.status, this)
                    }
                })
            },
            basvuruDetay(selection) {
                iletisimService.bayiBasvuruDetay(selection.basvuruId).then((response) => {
                    if (response.status === 200) {
                        this.basvuruDetayM = response.data;
                        this.drawer = true;
                    }
                })
            },
            basvuruDurumChange(selection, index, list, durum) {
                iletisimService.bayiBasvuruDurumGuncelle(selection.basvuruId,durum).then((response) => {
                    if (response.status === 200) {
                        notification.Status('success', this, 'İşlem başarıyla gerçekleşti.');
                        list.splice(index, 1);
                    } else {
                        notification.Status(response.status, this)
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    .el-radio-button--mini .el-radio-button__inner label{
        position: relative;
        bottom: 5px;
    }
</style>
<style lang="scss">
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .el-radio-button--mini .el-radio-button__inner{
        padding: 0 10px !important;
        font-size: 11px;
        border-radius: 0;
    }
    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }

    .el-drawer:focus {
        outline: none;
    }

    .el-drawer__header span:focus{
        outline: none;
    }
</style>